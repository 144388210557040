import Home from './pages/Home';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import ContactPage from './pages/ContactPage';
import BookPassPage from './pages/BookPassPage';
import DesignersPage from './pages/DesignersPage';
import Layout from './Layout';
import { Route, Routes } from 'react-router-dom';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="gallery" element={<GalleryPage />} />
        <Route path="connect" element={<ContactPage />} />
        <Route path="book-passes" element={<BookPassPage />} />
        {/* <Route path="designer-call" element={<DesignersPage />} /> */}
      </Route>
    </Routes>
  );
};

export default App;
