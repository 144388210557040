import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.garbinaagarba.com/",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Do something with response data
        return response;
    },
    (error) => {
        // Handle response error
        return Promise.reject(error);
    }
);

export default instance;
