import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/Home/Header";
import Footer from "./components/Home/Footer";
import image1 from "./images/logo.png";

const Layout = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className={`background app-content ${isMenuOpen ? "shifted" : ""}`}>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main>
        <div className="main-logo-div">
          <img src={image1} alt="Logo" className="main-logo" />
        </div>
        <Outlet /> {/* This will render the component for the current route */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
