import React, { useEffect, useState } from "react";
import "./bookpass.css";
import image1 from "../../images/logo.png";
import instance from "../../axios";
import { toast } from "react-toastify";
import { redirect, useNavigate } from "react-router-dom";
const Bookpass = () => {
  const [passes, setPasses] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [phaseString, setPhaseString] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isTACModalOpen, setIsTACModalOpen] = useState(false);
  const [isPPModalOpen, setIsPPModalOpen] = useState(false);
  const [formData, setFormData] = useState({ name: "", email: "", phone: "" });
  const [isChecked, setIsChecked] = useState(false);
  const [isPPChecked, setIsPPChecked] = useState(false);
  const [formDataResponse, setFormDataResponse] = useState({
    id: "",
    name: "",
    contact_number: "",
    email: "",
  });
  const [couponData, setCouponData] = useState({
    coupon_id: "",
  });
  const [reloadApi, serReloadApi] = useState(false);

  const totalQuantity = passes.reduce((acc, pass) => acc + pass.quantity, 0);
  const totalPrice = passes.reduce(
    (acc, pass) => acc + pass.quantity * pass.amount,
    0
  );
  const finalTotal = totalPrice - discount;

  useEffect(() => {
    console.log(isPPModalOpen);
  }, [isPPModalOpen]);

  useEffect(() => {
    const getPass = async () => {
      try {
        const response = await instance.get("pass_data");
        const updatedPasses = response.data.result.map((pass) => ({
          ...pass,
          quantity: 0,
        }));
        setPhaseString(response.data.phase_statement);
        setPasses(updatedPasses);
        setDiscount(0);
      } catch (error) {
        toast.error("Error Get Passes. Please try again."); // Error toast
      }
    };
    const UserData = sessionStorage.getItem("formData");
    try {
      const parsedData = JSON.parse(UserData);
      if (parsedData && parsedData.name) {
        const leadData = sessionStorage.getItem("leadData");
        setFormDataResponse(leadData ? JSON.parse(leadData) : null);
        setFormData(parsedData);
        setIsModalOpen(false);
      } else {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("Error parsing user data:", error);
      setIsModalOpen(true); // Default to opening the modal if there's an error
    }

    getPass();
  }, [reloadApi]);

  useEffect(() => {
    applyCoupon(); // This will run whenever `passes` is updated
  }, [passes]);

  const incrementPass = (index) => {
    const newPasses = [...passes];
    if (newPasses[index].quantity < newPasses[index].quantity_left) {
      newPasses[index].quantity += 1;
      setPasses(newPasses);
    }
  };

  const decrementPass = (index) => {
    const newPasses = [...passes];
    if (newPasses[index].quantity > 0) {
      newPasses[index].quantity -= 1;
      setPasses(newPasses);
    }
  };

  const applyCoupon = async () => {
    if (coupon.length > 0) {
      try {
        const response = await instance.get(
          `coupon?coupon=${coupon}&amount=${totalPrice}`
        );
        setDiscount(response.data.discount);
        setCouponData(response.data);
      } catch (error) {
        toast.error("Coupon is not valid");
        setDiscount(0);
      }
    }
  };

  const makePaymentApi = async () => {
    const errorData = validateForm();

    if (errorData.length > 0) {
      return;
    }
    if (totalPrice === 0) {
      toast.error("Please Add Pass"); // Error toast
      return;
    }
    try {
      const passQuantityMap = passes.reduce((acc, pass) => {
        if (pass.quantity !== 0) {
          acc[pass.pass_for] = pass.quantity;
        }
        return acc;
      }, {});
      const bodyData = {
        amount: totalPrice - discount,
        lead_pass_id: formDataResponse.id,
        pass_data: passQuantityMap,
        coupon_id: couponData.coupon_id, // Optional
      };
      const response = await instance.post("/make_payment", bodyData);
      toast.success(response.data.message); // Success toast
      return response.data.data;
    } catch (error) {
      toast.error("Please save the contact details"); // Error toast
    }
  };

  const handleRazorpayPayment = async () => {
    if (!isChecked) {
      toast.error(
        "Please agree to the terms and conditions before proceeding."
      );
      return;
    }
    if (!isPPChecked) {
      toast.error("Please agree to the privacy policy before proceeding.");
      return;
    }

    const errorData = validateForm();

    if (errorData.length > 0) {
      return;
    }
    if (totalPrice === 0) {
      toast.error("Please Add Pass"); // Error toast
      return;
    }

    try {
      // Fetch payment data from your API
      const makePaymentData = await makePaymentApi();

      if (makePaymentData) {
        const options = {
          key: makePaymentData.key, // Replace with your Razorpay key ID
          amount: makePaymentData.amount * 100, // Amount in paise
          currency: makePaymentData.currency,
          order_id: makePaymentData.razorpay_order_id,
          name: "Garbi Passes",
          image: { image1 },
          description: "Booking Passes for Garbi Event",
          handler: async function (response) {
            try {
              const apiResponse = await instance.post(
                "status_payment",
                response
              );
              serReloadApi(!reloadApi);
              if (apiResponse.data.is_success) {
                toast.success(
                  "Payment Received, your passes will be mailed to you within next 24 hours"
                );
              } else {
                toast.error(
                  "Unable to process the payment, if your amount is deducted then will be return back to you in 24 hours"
                );
              }
            } catch (error) {
              toast.error("Error submitting form. Please try again."); // Error toast
            }
          },
          prefill: {
            name: formDataResponse.name,
            email: formDataResponse.email,
            contact: formDataResponse.contact_number,
          },
          notes: {
            address: "Garba Event Venue",
          },
          theme: {
            color: "#F37254",
          },
          modal: {
            ondismiss: function () {
              // Handle payment modal dismissal (if necessary)
              toast.error("Payment process was cancelled!");
            },
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          // Handle failed payment
          toast.error("Payment failed! Please try again.");
          console.error("Payment Failed Response:", response);
          // You can also make an API call here to log the error
        });

        paymentObject.open();
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      toast.error("An error occurred while initiating the payment.");
      console.error("Error:", error);
    }
  };

  const validateForm = () => {
    const errors = [];
    if (!formData.name.trim()) {
      errors.push("Name is required and cannot be just spaces");
    } else if (!/^[a-zA-Z\s]+$/.test(formData.name.trim())) {
      errors.push("Name can only contain letters and spaces");
    }

    // Phone number validation
    const phonePattern = /^[6-9][0-9]{9}$/; // Phone number must start with 6, 7, 8, or 9 and be 10 digits long
    if (!formData.phone.trim()) {
      errors.push("Phone number is required");
    } else if (!phonePattern.test(formData.phone)) {
      errors.push(
        "Enter a valid 10-digit phone number starting with 6, 7, 8, or 9"
      );
    }

    // Email validation
    const emailPattern =
      /^[a-zA-Z0-9._%+-]+@(gmail\.com|hotmail\.com|google\.com|yahoo\.com|outlook\.com|icloud\.com|google\.co\.in|gmail\.co\.in|yahoo\.co\.in|apple\.com)$/;

    if (!formData.email.trim()) {
      errors.push("Email is required");
    } else if (!emailPattern.test(formData.email)) {
      errors.push(
        "Please use any one of these email provider : gmail.com,hotmail.com, google.com, yahoo.com, outlook.com, icloud.com, google.co.in, gmail.co.in, yahoo.co.in, apple.com"
      );
    }

    errors.forEach((error) => toast.error(error));

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      try {
        const bodyData = {
          name: formData.name,
          contact_number: formData.phone,
          email: formData.email,
        };
        const response = await instance.post("/lead", bodyData);
        setFormDataResponse(response.data);
        sessionStorage.setItem("formData", JSON.stringify(formData));
        sessionStorage.setItem("leadData", JSON.stringify(response.data));
        setIsModalOpen(false);
      } catch (error) {
        toast.error("Error submitting form. Please try again."); // Error toast
      }
    } else {
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClear = () => {
    setDiscount(0);
    setCoupon("");
  };

  return (
    <div>
      {isTACModalOpen ? (
        <div className="modal-overlay">
          <div className="modal-content-TAC">
            <div className="modal-close-div">
              <button
                className="modal-close"
                onClick={() => setIsTACModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <img src={image1} alt="Logo" className="modal-logo" />
            <div>
              <h2>Terms and Conditions</h2>
              <div style={{ lineHeight: "1.6", color: "#555" }}>
                <h3>1. Event Dates</h3>
                <p>
                  The Garba event will be held from 3rd October 2024 to 12th
                  October 2024.
                </p>

                <h3>2. Registration and Payment</h3>
                <p>
                  Participants must complete the registration form to secure
                  their spot. Early bird registration is priced at ₹1500 per
                  pass. Registration will be confirmed only after payment is
                  received. We will contact you with payment instructions after
                  your registration is submitted.
                </p>
                <p>
                  Early Bird Season Pass are priced at ₹8000 per pass.
                  Registration will be confirmed only after payment is received.
                  We will contact you with payment instructions after your
                  registration is submitted.
                </p>

                <h3>3. Entry Pass</h3>
                <p>
                  Each pass is valid for one person and for one day only. Passes
                  are non-transferable and cannot be reused.
                </p>

                <h3>4. Cancellation and Refunds</h3>
                <p>
                  No refunds will be issued for cancellations after payment.
                </p>
                <p>
                  No refunds will be provided in case of events affected by acts
                  of God or unforeseen circumstances beyond the organizers'
                  control.
                </p>

                <h3>5. Code of Conduct</h3>
                <p>
                  Attendees are expected to adhere to the event’s rules and
                  guidelines. Any behavior deemed inappropriate may result in
                  removal from the event without a refund.
                </p>

                <h3>6. Liability</h3>
                <p>
                  The organizers are not responsible for any loss or damage to
                  personal belongings.
                </p>
                <p>Attendees participate at their own risk.</p>

                <h3>7. Photography and Videography</h3>
                <p>
                  By attending, you consent to being photographed or filmed. The
                  organizers may use these images for promotional purposes.
                </p>

                <h3>8. Changes to Terms</h3>
                <p>
                  The organizers reserve the right to modify these terms and
                  conditions without prior notice.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : isPPModalOpen ? (
        <div className="modal-overlay">
          <div className="modal-content-TAC">
            <div className="modal-close-div">
              <button
                className="modal-close"
                onClick={() => setIsPPModalOpen(false)}
              >
                &times;
              </button>
            </div>
            <img src={image1} alt="Logo" className="modal-logo" />
            <div>
              <h1>Privacy Policy</h1>

              <h2>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</h2>
              <p>
                When you purchase something from our website, as part of the
                buying and selling process, we collect the personal information
                you give us such as your name, phone number, and email address.
              </p>
              <p>
                When you browse our website, we also automatically receive your
                computer’s internet protocol (IP) address in order to provide us
                with information that helps us learn about your browser and
                operating system.
              </p>
              <p>
                Email marketing (if applicable): With your permission, we may
                send you emails about our garba passes and other necessary
                information.
              </p>

              <h2>SECTION 2 - CONSENT</h2>
              <h3>How do you get my consent?</h3>
              <p>
                When you provide us with personal information to complete a
                transaction, verify your credit card, place an order, arrange
                for a delivery, or return a purchase, we imply that you consent
                to our collecting it and using it for that specific reason only.
              </p>
              <p>
                If we ask for your personal information for a secondary reason,
                like marketing, we will either ask you directly for your
                expressed consent or provide you with an opportunity to say no.
              </p>

              <h3>How do I withdraw my consent?</h3>
              <p>
                If after you opt-in, you change your mind, you may withdraw your
                consent for us to contact you, for the continued collection, use
                or disclosure of your information, at any time, by contacting us
                at{" "}
                <a href="mailto:info@garbinaagarba.com">
                  info@garbinaagarba.com
                </a>
                .
              </p>

              <h2>SECTION 3 - DISCLOSURE</h2>
              <p>
                We may disclose your personal information if we are required by
                law to do so or if you violate our Terms of Service.
              </p>

              <h2>SECTION 4 - PAYMENT</h2>
              <p>
                We use Razorpay for processing payments. We/Razorpay do not
                store your card data on their servers. The data is encrypted
                through the Payment Card Industry Data Security Standard
                (PCI-DSS) when processing payment. Your purchase transaction
                data is only used as long as is necessary to complete your
                purchase transaction. After that is complete, your purchase
                transaction information is not saved.
              </p>
              <p>
                Our payment gateway adheres to the standards set by PCI-DSS as
                managed by the PCI Security Standards Council, which is a joint
                effort of brands like Visa, MasterCard, American Express, and
                Discover.
              </p>
              <p>
                For more insight, you may also want to read terms and conditions
                of Razorpay on{" "}
                <a href="https://razorpay.com" target="_blank">
                  https://razorpay.com
                </a>
                .
              </p>

              <h2>SECTION 5 - THIRD-PARTY SERVICES</h2>
              <p>
                In general, the third-party providers used by us will only
                collect, use, and disclose your information to the extent
                necessary to allow them to perform the services they provide to
                us.
              </p>
              <p>
                However, certain third-party service providers, such as payment
                gateways and other payment transaction processors, have their
                own privacy policies in respect to the information we are
                required to provide to them for your purchase-related
                transactions.
              </p>
              <p>
                For these providers, we recommend that you read their privacy
                policies so you can understand the manner in which your personal
                information will be handled by these providers.
              </p>
              <p>
                In particular, remember that certain providers may be located in
                or have facilities that are located in a different jurisdiction
                than either you or us. So if you elect to proceed with a
                transaction that involves the services of a third-party service
                provider, then your information may become subject to the laws
                of the jurisdiction(s) in which that service provider or its
                facilities are located.
              </p>
              <p>
                Once you leave our website or are redirected to a third-party
                website or application, you are no longer governed by this
                Privacy Policy or our website’s Terms of Service.
              </p>

              <h3>Links</h3>
              <p>
                When you click on links on our store, they may direct you away
                from our site. We are not responsible for the privacy practices
                of other sites and encourage you to read their privacy
                statements.
              </p>

              <h2>SECTION 6 - SECURITY</h2>
              <p>
                To protect your personal information, we take reasonable
                precautions and follow industry best practices to make sure it
                is not inappropriately lost, misused, accessed, disclosed,
                altered, or destroyed.
              </p>

              <h2>SECTION 7 - COOKIES</h2>
              <p>
                We use cookies to maintain the session of your user. It is not
                used to personally identify you on other websites.
              </p>

              <h2>SECTION 8 - AGE OF CONSENT</h2>
              <p>
                By using this site, you represent that you are at least the age
                of majority in your state or province of residence, or that you
                are the age of majority in your state or province of residence
                and you have given us your consent to allow any of your minor
                dependents to use this site.
              </p>

              <h2>SECTION 9 - CHANGES TO THIS PRIVACY POLICY</h2>
              <p>
                We reserve the right to modify this privacy policy at any time,
                so please review it frequently. Changes and clarifications will
                take effect immediately upon their posting on the website. If we
                make material changes to this policy, we will notify you here
                that it has been updated, so that you are aware of what
                information we collect, how we use it, and under what
                circumstances, if any, we use and/or disclose it.
              </p>
              <p>
                If our store is acquired or merged with another company, your
                information may be transferred to the new owners so that we may
                continue to sell products to you.
              </p>

              <h2>QUESTIONS AND CONTACT INFORMATION</h2>
              <p>
                If you would like to: access, correct, amend or delete any
                personal information we have about you, register a complaint, or
                simply want more information contact our Privacy Compliance
                Officer at{" "}
                <a href="mailto:info@garbinaagarba.com">
                  info@garbinaagarba.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      ) : (
        <>
          <h2 className="header-text">{phaseString}</h2>
          <div className="bookpass-user-details block md:hidden">
            <div className="modal-content">
              <form onSubmit={handleSubmit}>
                <p className="font-bold text-lg">Contact Details </p>
                <div>
                  <label>Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Email:</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div>
                  <label>Phone Number:</label>
                  <input
                    type="tel"
                    name="phone"
                    maxLength={10}
                    pattern="\d{10}"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                </div>

                <button type="submit">
                  {formDataResponse.name ? "Edit" : "Save"}{" "}
                </button>
              </form>
            </div>
          </div>
          <div className="bookpass-container">
            <div className="passes">
              <div className="days-pass">
                {passes.map((pass, index) => (
                  <div className="card" key={index}>
                    {pass.sold_out && (
                      <span className="pass-sold-out-overlay">Sold Out</span>
                    )}
                    <div className="product-name">
                      <span className="font-bold text-xl	">
                        {pass.pass_show}
                      </span>
                      <br />
                      <span>{pass.date}</span>
                      <br />
                      <span>{pass.day}</span>
                    </div>
                    <div className="product-details">
                      <div>
                        <span className="product-price">
                          <b>
                            <del> Rs.{pass.pass_for === 11 ? 11999 : 1499}</del>
                          </b>
                        </span>
                      </div>
                      <div>
                        <span className="product-price">Rs.{pass.amount}</span>
                      </div>
                      <div className="quantity-control">
                        <button
                          onClick={() => decrementPass(index)}
                          disabled={pass.quantity === 0 || pass.sold_out}
                          className={`btn ${
                            pass.quantity === 0 || pass.sold_out
                              ? "disabled"
                              : ""
                          }`}
                        >
                          -
                        </button>
                        <span className="quantity">{pass.quantity}</span>
                        <button
                          onClick={() => incrementPass(index)}
                          disabled={pass.sold_out}
                          className={`btn ${pass.sold_out ? "disabled" : ""}`}
                        >
                          +
                        </button>
                      </div>

                      {pass.pass_for !== 11 ? (
                        <div>
                          <span className={`product-price `}>
                            Passes Left: {pass.quantity_left}
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="bookpass-main">
              <div className="bookpass-details">
                <p>Quantity: {totalQuantity}</p>
                <p>Total: ₹ {totalPrice}</p>
                <div className="flex items-center">
                  <input
                    type="text"
                    placeholder="Enter coupon code"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    className="border p-2 rounded"
                  />
                  <button
                    onClick={handleClear}
                    className="ml-2 bg-gray-300 p-2 rounded hover:text-white"
                  >
                    Clear
                  </button>
                </div>
                <button className="apply-coupon-button" onClick={applyCoupon}>
                  Apply Coupon
                </button>
                {discount > 0 && (
                  <>
                    <p>Discount: ₹ {discount.toFixed(2)}</p>
                    <p>Final Total: ₹ {finalTotal.toFixed(2)}</p>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    value="Bike"
                    checked={isChecked}
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <span
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#730000",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      transition: "color 0.3s, text-decoration 0.3s",
                    }}
                    onClick={() => setIsTACModalOpen(true)}
                  >
                    Terms and Conditions
                  </span>
                </div>

                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    value="Privacy Policy"
                    checked={isPPChecked}
                    onChange={(e) => {
                      console.log(e.target.checked);
                      setIsPPChecked(e.target.checked);
                    }}
                  />
                  <span
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                      fontSize: "14px",
                      color: "#730000",
                      textDecoration: "underline",
                      fontWeight: "bold",
                      transition: "color 0.3s, text-decoration 0.3s",
                    }}
                    onClick={() => setIsPPModalOpen(true)}
                  >
                    Privacy Policy
                  </span>
                </div>

                <button
                  className="checkout-button"
                  onClick={handleRazorpayPayment}
                >
                  Proceed to Checkout
                </button>
              </div>
              <div className="bookpass-user-details hidden md:block">
                <div className="modal-content">
                  <form onSubmit={handleSubmit}>
                    <p className="font-bold text-lg">Contact Details </p>
                    <div>
                      <label>Name:</label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div>
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <div>
                      <label>Phone Number:</label>
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        maxLength={10}
                        pattern="\d{10}"
                        onChange={handleChange}
                        required
                      />
                    </div>

                    <button type="submit">
                      {formDataResponse.name ? "Edit" : "Save"}{" "}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Bookpass;
